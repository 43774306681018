<!-- =========================================================================================
	File Name: ItemGridView.vue
	Description: Item Component - Grid VIew
	----------------------------------------------------------------------------------------
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Author: Pixinvent
	Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <!-- <div class="item-grid-view vx-row match-height">
        <div class="vx-col" :class="gridColumnWidth" v-for="item in items" :key="item.objectID"> -->
  <vx-card class="grid-view-item mb-base overflow-hidden" v-on="$listeners">
    <template slot="no-body">
      <!-- ITEM IMAGE -->
      <div
        class="item-img-container bg-white h-64 flex items-center justify-center cursor-pointer"
        @click="navigate_to_detail_view(item)"
      >
        <img
          :src="defalt"
          :alt="item.name"
          class="grid-view-img px-4"
          v-if="item.image == null || item.image == ''"
        />
        <img
          :src="item.image"
          :alt="item.name"
          class="grid-view-img px-4"
          v-else
        />
      </div>
      <div class="item-details px-4">   

        <!-- TITLE & DESCRIPTION -->
        <div class="my-4">
          <h6
            class="truncate font-semibold mb-1 hover:text-primary cursor-pointer"
            @click="navigate_to_detail_view(item)"
          >
            {{ item.name }}
          </h6>
          <!--  <p class="item-description truncate text-sm">{{ getHtmlValue(item.description) }}</p> -->
        </div>
      </div>

      <!-- SLOT: ACTION BUTTONS -->
      <slot name="action-buttons" />
    </template>
  </vx-card>
  <!--  </div>
    </div> -->
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      defalt:
        'https://taxandsuperaus.s3.ap-southeast-2.amazonaws.com/tsa_events/30/events/images/1586696311486-ca655453eb79fe8db19601dfcf53ed95.jpg'
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.grid-view-item {
  .grid-view-img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    transition: 0.35s;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
</style>
